var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-options" },
    [
      _vm.domainSearchAvailable
        ? _c("div", { staticClass: "available-domain-container" }, [
            _c("div", { staticClass: "inform-text" }, [
              _c("span", [
                _vm._v(
                  "\n        " + _vm._s(_vm.text.availableDomain) + "\n      "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "available-domain" },
              [
                _c("span", { class: _vm.setOptionAsSelected }, [
                  _vm._v("\n        " + _vm._s(_vm.domainSearch) + "\n      "),
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "domain-button",
                    attrs: {
                      variant: _vm.applyButtonVariant,
                      "aria-label": "Select Domain Button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDomainSelect(_vm.domainSearch)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.selectedDomain === _vm.domainSearch
                            ? "Selected"
                            : "Available"
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "inform-text" }, [
        _c("span", [_vm._v("\n      " + _vm._s(_vm.text.options) + "\n    ")]),
      ]),
      _c("ct-table", {
        class: "domain-table " + _vm.displayDomainTable,
        attrs: {
          items: _vm.alternativeOptions,
          fields: _vm.tableFields,
          "is-paginated": false,
          small: false,
          stacked: false,
          loaded: _vm.domainSearchResults !== null,
          "hide-headers": true,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(domain)",
            fn: function ({ item }) {
              return [
                _c(
                  "span",
                  {
                    class:
                      _vm.selectedDomain && _vm.selectedDomain !== item.domain
                        ? "non-selected-domain"
                        : "selected-domain",
                  },
                  [_vm._v("\n        " + _vm._s(item.domain) + "\n      ")]
                ),
              ]
            },
          },
          {
            key: "cell(select_button)",
            fn: function ({ item }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "domain-button",
                    attrs: {
                      variant:
                        _vm.selectedDomain === item.domain
                          ? "primary"
                          : "outline-primary",
                      "aria-label": "Select Domain Button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDomainSelect(item.domain)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.selectedDomain === item.domain
                            ? "Selected"
                            : "Available"
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }